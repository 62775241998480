import { FC, memo } from 'react';
import { Checkbox } from '@mui/material';

import { IFileUpload } from '/common/models';
import { useStateChange } from '/common/useStateChange';

import { IUploadListProps } from './types';
import { UploadItem } from './UploadItem';

export const UploadList: FC<IUploadListProps> = memo((props) => {
  const {
    state,
    errors,
    selected,
    progress,
    processing,
    adapter,
    fileIDs,
    fileRevisionID,
    checkExisting,
    shouldShowMasterSelect,

    generateName,
    onRemove,
    onReset,
    onUpdateSingle,
    onUploadSingle,
    onToggleSelect,
    onRemoveTag,
    onMasterSelect,
  } = props;

  useStateChange(false);

  const isAllSelected = selected.length === state.ids.length;

  return (
    <>
      {shouldShowMasterSelect && <Checkbox checked={isAllSelected} sx={{ mb: -4 }} onChange={onMasterSelect} />}

      {adapter.selectAll(state).map((e: IFileUpload) => {
        const id = adapter.selectID(e);
        const process = Boolean(processing.has(id));
        const error = errors[id];
        const checked = selected.includes(id);
        const itemProgress = progress[e.id];
        const fileID = fileIDs[id];

        const hasMissedUpload = fileID !== undefined || !!fileRevisionID;

        return (
          <UploadItem
            key={id}
            disabled={hasMissedUpload}
            entity={e}
            processing={process}
            selected={checked}
            error={error}
            progress={itemProgress}
            fileID={fileID}
            fileRevisionID={fileRevisionID}
            checkExisting={checkExisting}
            generateName={generateName}
            onRemove={onRemove}
            onReset={onReset}
            onUpdate={onUpdateSingle}
            onUpload={onUploadSingle}
            onToggleSelect={onToggleSelect}
            onRemoveTag={onRemoveTag}
          />
        );
      })}
    </>
  );
});
